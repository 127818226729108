<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>电子面单</el-breadcrumb-item>

        <el-breadcrumb-item> 保存及打印前请先安装打印组件，下载地址
          <a href="http://www.c-lodop.com/download/CLodop_Setup_for_Win64NT_6.572EN.zip"
            style="color: blue; cursor: pointer;">点击下载</a>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form class="search-form">
      <el-row>
        <el-form-item label="选择打印机">
          <el-col>
            <el-select v-model="printIdx" placeholder="打印机" style="width: 220px">
              <el-option v-for="item in printDev" :key="item.index" :label="item.name" :value="item.index">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>

    <el-tabs type="border-card" v-model="tabName" @tab-change="tabChange">
      <el-tab-pane label="打印当前面单" name="current" class="horizbox">
        <el-form label-width="90px" class="search-form">
          <el-row>
            <el-form-item label="" label-width="20px">
              <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
            </el-form-item>

            <el-form-item label="" label-width="20px">
              <el-button plain type="primary" icon="printer" @click="printSaveEvent()">保存并打印面单</el-button>
            </el-form-item>
          </el-row>
        </el-form>

        <!-- 电子面单打印 -->
        <el-table :data="printData" stripe border size="small" height="450">
          <el-table-column prop="orderNo" label="平台订单号" width="190" show-overflow-tooltip sortable>
            <template #header>
              <el-checkbox label="全选" @change="checkAllChanges" v-model="checkAll">平台订单号</el-checkbox>
            </template>
            <template #default="scope">
              <el-checkbox-group v-model="checkList" v-if="!scope.row.expressPostNo" size="small">
                <el-checkbox v-for="value in scope.row.orderNo.split(',')" :key="value" :label="value"
                  style="display: block; height: 25px; line-height: 25px;" />
              </el-checkbox-group>
            </template>
          </el-table-column>
          <!-- <el-table-column type="index" align="center" label="序号" width="45"> </el-table-column> -->
          <el-table-column prop="dataFrom" label="平台" width="70" show-overflow-tooltip sortable> </el-table-column>
          <!-- <el-table-column prop="orderNo" label="平台订单号" width="150" show-overflow-tooltip>
          <template #default="scope">
            <div v-for="value in scope.row.orderNo.split(',')" :key="value">{{ value }}</div>
          </template>
        </el-table-column> -->

          <el-table-column prop="receiverName" label="收件人" width="80" show-overflow-tooltip sortable> </el-table-column>
          <el-table-column prop="receiverMobile" label="手机号" width="100" show-overflow-tooltip sortable>
          </el-table-column>
          <!-- 
          <el-table-column prop="receiverProvince" label="省" width="60" show-overflow-tooltip>
            <template #default="scope">
              <el-text size="small" @click="editProvince = true" v-if="!editProvince">
                {{ scope.row.receiverProvince }}
              </el-text>
              <el-input v-model="scope.row.receiverProvince" v-if="editProvince"
                @keyup.enter="editProvince = false"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="receiverCity" label="市" width="60" show-overflow-tooltip>
            <template #default="scope">
              <el-text size="small" @click="editCity = true" v-if="!editCity">
                {{ scope.row.receiverCity }}
              </el-text>
              <el-input v-model="scope.row.receiverCity" v-if="editCity" @keyup.enter="editCity = false"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="receiverArea" label="区" width="60" show-overflow-tooltip>
            <template #default="scope">
              <el-text size="small" @click="editArea = true" v-if="!editArea">
                {{ scope.row.receiverArea }}
              </el-text>
              <el-input v-model="scope.row.receiverArea" v-if="editArea" @keyup.enter="editArea = false"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="receiverTown" label="街道" width="70" show-overflow-tooltip>
            <template #default="scope">
              <el-text size="small" @click="editTown = true" v-if="!editTown">
                {{ scope.row.receiverTown }}
              </el-text>
              <el-input v-model="scope.row.receiverTown" v-if="editTown" @keyup.enter="editTown = false"></el-input>
            </template>
          </el-table-column> -->
          <el-table-column prop="receiverAddress" width="190" label="地址(点击可修改)" show-overflow-tooltip sortable>
            <template #default="scope">
              <el-text size="small" @click="editName = 'province'" v-if="editName == ''">
                {{ scope.row.receiverProvince }}
              </el-text>
              <el-input v-model="scope.row.receiverProvince" v-if="editName" @keyup.enter="editName = ''"
                style="max-width: 80px;"></el-input>
              &nbsp;
              <el-text size="small" @click="editName = 'city'" v-if="editName == ''">
                {{ scope.row.receiverCity }}
              </el-text>
              <el-input v-model="scope.row.receiverCity" v-if="editName" @keyup.enter="editName = ''"
                style="max-width: 75px;"></el-input>
              <br />

              <el-text size="small" @click="editName = 'area'" v-if="editName == ''">
                {{ scope.row.receiverArea }}
              </el-text>
              <el-input v-model="scope.row.receiverArea" v-if="editName" @keyup.enter="editName = ''"
                style="max-width: 80px;"></el-input>
              &nbsp;
              <el-text size="small" @click="editName = 'town'" v-if="editName == ''">
                {{ scope.row.receiverTown }}
              </el-text>
              <el-input v-model="scope.row.receiverTown" v-if="editName" @keyup.enter="editName = ''"
                style="max-width: 80px;"></el-input>
              <br />
              <el-text size="small" @click="editName = 'addr'" v-if="editName == ''">
                {{ scope.row.receiverAddress }}
              </el-text>
              <el-input v-model="scope.row.receiverAddress" v-if="editName" @keyup.enter="editName = ''"
                style="max-width: 170px;"></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="receiverZipCode" width="70" label="邮编"> </el-table-column> -->
          <el-table-column prop="quantity" label="数量" width="50"> </el-table-column>
          <el-table-column prop="expressCode" width="100" label="快递公司">
            <template #header>
              <el-select v-model="expressId" placeholder="设置" size="small" style="width: 100%"
                @change="changeAllExpress">
                <el-option v-for="item in dict.express" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </template>
            <template #default="scope">
              <el-select v-model="scope.row.expressId" placeholder="快递" size="small" style="width: 100%">
                <el-option v-for="item in dict.express" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="expressPostNo" width="80" label="快递单号" show-overflow-tooltip>
            <template #default="scope">
              <span v-if="(scope.row.expressPostNo || '').length > 0">{{ scope.row.expressPostNo }}</span>
              <span v-else>打印后返回</span>
            </template>
          </el-table-column>
          <el-table-column prop="insureFee" width="105" label="投保金额(元)">
            <template #default="scope">
              <el-input-number v-model="scope.row.insureFee" :precision="0" :step="100" size="small"
                controls-position="right" style="max-width: 100px; margin-left: -3px;"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="senderName" width="90" label="发件人" sortable>
            <template #default="scope">
              <el-select v-model="scope.row.senderName" placeholder="" size="small" style="width: 100%">
                <el-option v-for="item in dict.senders" :key="item.senderName" :label="item.senderName"
                  :value="item.senderName"> </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip sortable>
            <template #default="scope">
              <div v-for="value in scope.row.remark.split('\n')" :key="value">{{ value }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="历史保存面单" name="history" class="horizbox">
        <el-form label-width="90px" class="search-form">
          <el-row>
            <el-form-item label="关键词" prop="keyword" label-width="70px">
              <el-col>
                <el-input v-model="historyData.keyword" @keyup.enter="fetchs" prefix-icon="Search"
                  style="width:150px"></el-input>
              </el-col>
            </el-form-item>

            <el-form-item label="时间" label-width="90px">
              <el-col>
                <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
                  unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:200px;">
                </el-date-picker>
              </el-col>
            </el-form-item>

            <el-form-item label="" label-width="20px">
              <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
            </el-form-item>

            <el-form-item label="" label-width="20px">
              <el-button plain type="primary" icon="takeawayBox" @click="batchSubmitEvent"
                :disabled="!showResubmitBtn">重新下单并打印</el-button>
              <el-button plain type="primary" icon="printer" @click="batchPrintEvent"
                :disabled="!showReprintBtn">打印历史面单</el-button>
            </el-form-item>

            <el-button plain type="info" icon="download" @click="printExportEvent()"
              style="position:absolute; right: 0;">
              导出明细
            </el-button>
          </el-row>
        </el-form>

        <static-table class="table" :pager="historyPager" :columns="historyData.columns" :currHandler="currHandler"
          :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">
          <!-- <template v-slot:status="scope">
            <span style="color:#79bbff" v-if="scope.row.status == 1">成功</span>
            <span style="color:#e6a23c" v-else-if="scope.row.status == 3">取消</span>
            <span style="color:#f56c6c" v-else>失败</span>
          </template> -->
          <template v-slot:orderNo="scope">
            <div v-for="value in scope.row.orderNo.split(',')" :key="value">{{ value }}</div>
          </template>
          <!-- <template v-slot:receiverAddress="scope">
            {{ scope.row.receiverProvince }}{{ scope.row.receiverCity }}
            {{ scope.row.receiverArea }}{{ scope.row.receiverTown }}{{ scope.row.receiverAddress }}
          </template> -->
          <template v-slot:receiverAddress="scope">
            <el-text size="small" @click="editName = 'province'" v-if="editName == ''">
              {{ scope.row.receiverProvince }}
            </el-text>
            <el-input v-model="scope.row.receiverProvince" v-if="editName" @keyup.enter="editName = ''"
              style="max-width: 80px;" placeholder="省"></el-input>
            &nbsp;
            <el-text size="small" @click="editName = 'city'" v-if="editName == ''">
              {{ scope.row.receiverCity }}
            </el-text>
            <el-input v-model="scope.row.receiverCity" v-if="editName" @keyup.enter="editName = ''"
              style="max-width: 80px;" placeholder="市"></el-input>
            <br />

            <el-text size="small" @click="editName = 'area'" v-if="editName == ''">
              {{ scope.row.receiverArea }}
            </el-text>
            <el-input v-model="scope.row.receiverArea" v-if="editName" @keyup.enter="editName = ''"
              style="max-width: 80px;" placeholder="区"></el-input>
            &nbsp;
            <el-text size="small" @click="editName = 'town'" v-if="editName == ''">
              {{ scope.row.receiverTown }}
            </el-text>
            <el-input v-model="scope.row.receiverTown" v-if="editName" @keyup.enter="editName = ''"
              style="max-width: 100px;" placeholder="街道"></el-input>
            <br />
            <el-text size="small" @click="editName = 'addr'" v-if="editName == ''">
              {{ scope.row.receiverAddress }}
            </el-text>
            <el-input v-model="scope.row.receiverAddress" v-if="editName" @keyup.enter="editName = ''"
              style="max-width: 150px;" placeholder="详细地址"></el-input>
          </template>

          <template v-slot:expressName="scope">
            <el-select v-model="scope.row.expressId" placeholder="" v-if="scope.row.status != 1" size="small"
              style="width: 100%">
              <el-option v-for="item in dict.express" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-text v-else>{{ scope.row.expressBizName || scope.row.expressName }}</el-text>
          </template>
          <template v-slot:expressPostNo="scope">
            <span v-if="(scope.row.expressPostNo || '').length > 0">{{ scope.row.expressPostNo }}</span>
            <span v-else>打印后返回</span>
          </template>
          <template v-slot:senderName="scope">
            <el-select v-model="scope.row.senderName" placeholder="" :disabled="scope.row.status == 1" size="small"
              style="width: 100%">
              <el-option v-for="item in dict.senders" :key="item.senderName" :label="item.senderName"
                :value="item.senderName"> </el-option>
            </el-select>
          </template>
          <template v-slot:remark="scope">
            <div v-for="value in scope.row.remark.split('\n')" :key="value">{{ value }}</div>
          </template>
          <template v-slot:toolbar="scope">
            <span v-if="scope.row.status != 1" style="color:#f56c6c">失败</span>
            <el-button v-if="scope.row.status == 1" @click="singlePrintEvent(scope.row.templateHtml)" type="primary"
              link size="small">
              [重打]
            </el-button>
            <el-button v-if="scope.row.status == 1" @click="cancelPrintEvent(scope.row.id)" type="warning" link
              size="small" style="margin-left:0;">
              [取消]
            </el-button>
          </template>
        </static-table>
      </el-tab-pane>

    </el-tabs>


  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { orderPrintDto, fetchOrderPrint, savePrintData, cancelPrintData } from "@/api/order";
export default {
  name: "orderPrintInfo",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      dev: null,
      tabName: 'current',
      status: null,
      platform: null,
      idsData: '',

      printData: [],
      checkAll: false,
      allList: [],
      checkList: [],

      //expressCode: null,
      expressId: null,

      currRow: null,
      selects: [],
      loading: false,

      historyData: {
        keyword: "",
        filters: [],
        columns: [
          { type: "selection", width: "40" },
          { type: "index", label: "序号" },
          { type: "template", prop: "orderNo", label: "订单号", width: "170", searchable: true, showOverflowTooltip: true },
          { prop: "dataFrom", label: "来源", width: "60", searchable: true, showOverflowTooltip: true, },
          { prop: "receiverName", label: "收件人", width: "70", searchable: true, showOverflowTooltip: true },
          { prop: "receiverMobile", label: "手机号", width: "90", searchable: true, showOverflowTooltip: true, },

          { type: "hidden", prop: "receiverProvince", label: "省份", searchable: true },
          { type: "hidden", prop: "receiverCity", label: "城市", searchable: true },
          { type: "hidden", prop: "receiverArea", label: "县/区", searchable: true },
          { type: "hidden", prop: "receiverTown", label: "镇/街道", searchable: true },
          { type: "template", prop: "receiverAddress", label: "地址", width: "190", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "quantity", label: "数量", width: "45", showOverflowTooltip: true, },
          { type: "hidden", prop: "status", label: "状态", width: "50", showOverflowTooltip: true, },

          { type: "hidden", prop: "expressCode", label: "快递公司编号", searchable: true },
          { type: "template", prop: "expressName", label: "快递公司", searchable: true, width: "100", },
          { type: "hidden", prop: "expressBizCode", label: "快递业务编号", searchable: true },
          { type: "hidden", prop: "expressBizName", label: "快递业务名称", searchable: true },
          { type: "template", prop: "expressPostNo", label: "快递单号", searchable: true, width: "100", showOverflowTooltip: true, },

          { type: "hidden", prop: "templateHtml", label: "打印模板", },
          { prop: "insureFee", label: "投保额", width: "60" },

          { type: "template", prop: "senderName", label: "发件人", width: "90", },

          { type: "template", prop: "remark", label: "备注", width: "130", sortable: true, searchable: true, showOverflowTooltip: true, },
          { prop: "reason", label: "原因", width: "100", showOverflowTooltip: true, },
          { prop: "createDate", label: "时间", sortable: true, width: "140", showOverflowTooltip: true, },
          { type: "toolbar", label: "操作", width: "80" },
        ],
      },
      historyPager: {
        sidx: "id",
        sord: "descending",
      },

      dict: {
        express: [],
        senders: [
          {
            "senderName": "陈先生",
            "senderMobile": "17322331332",
            "senderProvince": "广东省",
            "senderCity": "深圳市",
            "senderArea": "罗湖区",
            "senderAddress": "东晓街道独树社区翠竹北路5号水贝石化工业区2栋2层东",
          }
        ]
      },

      dateRange: [],

      printIdx: -1, // -1默认打印机
      printDev: [],

      editName: ''
    };
  },
  methods: {
    tabChange() {
      this.fetchs();

      this.checkAll = true;
      this.checkList = this.allList;
      this.nocheckedList = [];
    },
    fetchs() {
      if (this.tabName == "current") {
        this.loadCurrent();
      } else if (this.tabName == "history") {
        this.loadHistory();
      }
    },

    loadCurrent() {
      let that = this;
      // let postData = [];
      // this.selects.forEach(element => {
      //   postData.push({ id: element.id })
      // });
      that.allList = [];
      orderPrintDto(this.tabName, this.status, this.selects, this.platform, this.idsData).then(
        (res) => {
          if (res.code === 200) {
            this.printData = res.data;
            this.printData.forEach((res) => {
              res.senderName = this.dict.senders[0].senderName;
              res.orderNo.split(',').forEach((orderNo) => {
                if (orderNo || orderNo.length > 0)
                  that.allList.push(orderNo);
              });

              if (that.expressId) {
                var express = this.dict.express.filter(q => q.id == that.expressId)[0];
                res.expressId = express.id;
                res.expressCode = express.value;
              }
            });
          }
        },
        (err) => {
          this.$alert(err.content, '提示')
        }
      )
    },

    loadHistory(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      let that = this;
      this.historyData.filters = [];
      let { data, records, total, ...search } = {
        ...this.historyPager,
        ...this.historyData,
      };

      if (this.dateRange && this.dateRange[0])
        this.historyData.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.historyData.filters.push({
          name: "createDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      search.method = methodName || '';

      fetchOrderPrint(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.historyPager = { ...res.data };
        }
      })
        .catch(() => {
          that.$message({ type: "info", message: "查询失败", });
        });
    },

    checkAllChanges() {
      if (this.checkAll) this.checkList = this.allList;
      else this.checkList = [];
    },
    changeAllExpress() {
      this.printData.forEach((res) => {
        //res.expressCode = this.expressCode;
        var express = this.dict.express.filter(q => q.id == this.expressId)[0];
        res.expressId = express.id;
        res.expressCode = express.value;
      });
    },

    printSaveEvent() {
      let checkedPrintData = [];
      //没有勾选的订单号
      let nocheckedList = this.allList.filter(item => !this.checkList.includes(item));

      if (this.printData.filter(row => !row.expressId).length > 0) {
        this.$alert('请选择对应订单的快递方式', '提示');
        return;
      }

      this.printData.forEach((row) => {
        //勾选的打印单
        if (row.orderNo.split(',').filter(q => q.length > 0 && this.checkList.indexOf(q) > -1).length > 0) {
          row.senderName = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderName;
          row.senderMobile = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderMobile;
          row.senderProvince = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderProvince;
          row.senderCity = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderCity;
          row.senderArea = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderArea;
          row.senderAddress = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderAddress;

          var express = this.dict.express.filter(q => q.id == row.expressId)[0];
          row.expressId = express.id;
          row.expressCode = express.value;
          row.expressName = express.name;
          row.expressBizName = express.name;
          row.expressBizCode = express.attribute;

          checkedPrintData.push(row);
        }
      });

      if (checkedPrintData.length == 0) {
        this.$alert('请勾选要保存并打印的单据信息', '提示');
        return;
      }

      let tipMessage = "确认保存并打印勾选的快递单据, 是否继续?";
      if (this.tabName == 'history') {
        tipMessage += "  （仅会打印失败的发货单据）";
      }

      console.log('checkedPrintData', checkedPrintData);
      if (!window.LODOP) {
        this.$message({ message: '打印组件加载错误，请安装打印组件后再尝试' });
        return;
      }

      this.$confirm(tipMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          savePrintData(checkedPrintData, nocheckedList).then(
            (res) => {
              if (res && res.code == 200) {
                //let templateHtmls = '';
                res.data.forEach((printRow) => {
                  if (printRow || printRow.templateHtml.length > 0) {
                    //templateHtmls += printRow.templateHtml + '<hr style="margin:10px 0px;">';
                    this.singlePrintEvent(printRow.templateHtml, 'no');
                  }
                });
                //this.singlePrintEvent(templateHtmls, 'no');

                this.fetchs();
              }
              this.$alert(res.content, '提示');
            },
            (err) => {
              this.$alert(err.content, '错误提示');
            }
          );
        })
    },
    batchSubmitEvent() {
      let failData = this.selects.filter(item => item.status != 1);
      if (failData.length == 0) {
        this.$message({ message: '请勾选下单失败的面单数据' });
        return;
      }

      if (failData.filter(row => !row.expressId).length > 0) {
        this.$alert('请选择对应订单的快递方式', '提示');
        return;
      }

      failData.forEach((row) => {
        //勾选的打印单
        row.senderName = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderName;
        row.senderMobile = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderMobile;
        row.senderProvince = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderProvince;
        row.senderCity = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderCity;
        row.senderArea = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderArea;
        row.senderAddress = this.dict.senders.filter(q => q.senderName == row.senderName)[0].senderAddress;

        //row.expressName = this.dict.express.filter(q => q.id == row.expressCode)[0].name;

        var express = this.dict.express.filter(q => q.id == row.expressId)[0];
        row.expressId = express.id;
        row.expressCode = express.value;
        row.expressName = express.name;
        row.expressBizName = express.name;
        row.expressBizCode = express.attribute;
      });

      console.log('failedPrintData', failData);
      if (!window.LODOP) {
        this.$message({ message: '打印组件加载错误，请安装打印组件后再尝试' });
        return;
      }

      this.$confirm(
        '确认要重新请求勾选的' + failData.length + '条快递信息并打印?', "快递下单打印提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", }
      )
        .then(() => {
          savePrintData(failData, []).then(
            (res) => {
              if (res && res.code == 200) {
                //let templateHtmls = '';
                res.data.forEach((printRow) => {
                  if (printRow || printRow.templateHtml.length > 0) {
                    //templateHtmls += printRow.templateHtml + '<hr style="margin:10px 0px;">';
                    this.singlePrintEvent(printRow.templateHtml, 'no');
                  }
                });
                //this.singlePrintEvent(templateHtmls, 'no');

                this.fetchs();
              }
              this.$alert(res.content, '提示');
            },
            (err) => {
              this.$alert(err.content, '错误提示');
            }
          );
        })
    },
    batchPrintEvent() {
      if (!window.LODOP) {
        this.$message({ message: '打印组件加载错误，请安装打印组件后再尝试' });
        return;
      }

      let succData = this.selects.filter(item => item.status == 1);
      if (succData.length == 0) {
        this.$message({ message: '请勾选已下单成功的面单数据' });
        return;
      }
      this.$confirm(
        '确认要批量打印勾选的' + succData.length + '条面单信息?', "批量打印提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", }
      )
        .then(() => {
          //let templateHtmls = '';
          succData.forEach(res => {
            //console.log(res);
            if (res || res.templateHtml.length > 0) {
              //templateHtmls += res.templateHtml + '<hr style="margin:10px 0px;">';
              this.singlePrintEvent(res.templateHtml, 'no')
            }
          });
          //this.singlePrintEvent(templateHtmls, 'no')
        })
    },
    singlePrintEvent(templateHtmls, preview) {
      preview = preview || 'yes';//默认是打印预览,而非直接打印
      let htmlContent = this.$util.decodeHTML(templateHtmls || '');
      if (htmlContent.length <= 200) {
        this.$alert('电子面单打印格式错误，请尝试重新下单或联系管理员', '打印中断');
        return;
      }

      let LODOP = window.LODOP;
      if (!window.LODOP) return;

      //huaxgj.com 域名授权
      LODOP.SET_LICENSES("", "952E9184053FC5370C11646084839488AB3", "", "");

      LODOP.PRINT_INIT("打印快递单");               //打印初始化
      //LODOP.ADD_PRINT_TEXT(0, 0, 100, 20, "文本内容一");//然后多个ADD语句及SET语句
      //ADD_PRINT_HTM(intTop,intLeft,intWidth,intHeight,strHtml)增加超文本项
      LODOP.ADD_PRINT_HTM(0, 2, "100%", "100%", htmlContent); //打印html

      //LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT","Full-Page");//整页缩放
      LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);//宽度溢出缩放
      //LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW",true);//高度溢出缩放

      //window.LODOP.GET_PRINTER_COUNT();  //获取打印机个数
      //window.LODOP.GET_PRINTER_NAME(1);  //获取指定的打印机名称
      //SET_PRINTER_INDEX(oIndexOrName);按序号或名称指定打印机，选定后禁止手工重选；
      //SET_PRINTER_INDEXA(IndexorName);按序号或名称指定打印机，选定后允许手工重选；

      LODOP.SET_PRINTER_INDEX(this.printIdx);

      //LODOP.PREVIEW();
      //LODOP.PRINT();

      //打印预览
      if (preview == 'yes') {
        LODOP.PREVIEW();
      }
      else {
        //最后一个打印(或预览、维护、设计)语句
        if (this.dev.NODE_ENV == 'production') {
          LODOP.PRINT();
        }
        else {
          console.log('非生产环境模拟打印[避免真实打印]', row, this.dev);
        }
        //LODOP.PRINT();
      }
    },
    cancelPrintEvent(id) {
      let that = this;
      this.$confirm("此操作将取消已经打印下单的快递单, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          cancelPrintData(id).then((res) => {
            that.$message({
              type: res.type,
              message: res.content,
            });
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      if (this.tabName == "current") {
      } else if (this.tabName == "history") {
        this.historyPager.page = page;
      }
      this.fetchs();
    },
    sizeHandler(rows) {
      if (this.tabName == "current") {
      } else if (this.tabName == "history") {
        this.historyPager.rows = rows;
      }
      this.fetchs();
    },
    sortHandler(order) {
      if (this.tabName == "current") {
      } else if (this.tabName == "history") {
        this.historyPager.sidx = order.prop;
        this.historyPager.sord = order.order;
      }

      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },
    printExportEvent() {
      this.loadHistory('Export');
    }
  },
  watch: {
    // orderPager: function (newValue, oldValue) {
    //   this.$util.setPageCache('keyword', newValue.keyword);
    //   if (newValue.page)
    //     this.$util.setPageCache('page', newValue.page);
    //   if (newValue.rows)
    //     this.$util.setPageCache('rows', newValue.rows);
    //   if (newValue.sidx)
    //     this.$util.setPageCache('sidx', newValue.sidx);
    //   if (newValue.sord)
    //     this.$util.setPageCache('sord', newValue.sord);
    // }
  },
  created: function () {
    this.dev = process.env;
    // this.orderParams.keyword = this.$util.getPageCache('keyword');

    // this.orderPager.page = parseInt(this.$util.getPageCache('page') || 1);
    // this.orderPager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    // let sidx = this.$util.getPageCache('sidx');
    // if (sidx) this.orderPager.sidx = sidx;
    // let sord = this.$util.getPageCache('sord');
    // if (sord) this.orderPager.sord = sord;

    this.tabName = this.$route.query.tabName||this.tabName;

    this.status = this.$route.query.status || '10';
    this.platform = this.$route.query.platform || '';
    this.idsData = this.$route.query.ids || '';

    this.$util.loadJs("http://localhost:8000/CLodopfuncs.js?name=CLODOPA").then(() => {
      // 加载成功，进行后续操作
      console.log('打印组件加载成功');

      this.printDev.push({ index: -1, name: '默认打印机' });
      for (let i = 0; i < window.LODOP.GET_PRINTER_COUNT(); i++) {
        this.printDev.push({ index: i, name: window.LODOP.GET_PRINTER_NAME(i) });
      }
    }, (err) => {
      console.log('加载失败', err);
      this.printDev.push({ index: -1, name: '打印组件加载失败' });
      this.$alert('打印组件加载失败，请检查是否安装打印组件或是否开启打印组件', '提示');
    });

    this.$kaung.dictionary("stock", "express").then(res => this.dict.express = res);
    this.tabChange();
  },
  computed: {
    showReprintBtn() {
      return (this.selects.filter(item => item.status == 1).length > 0);
    },
    showResubmitBtn() {
      return (this.selects.filter(item => item.status != 1).length > 0);
    },
  },
};
</script>
<style lang="scss" scoped></style>